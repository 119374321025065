import { ApexOptions } from 'apexcharts'
import { StatsCA } from 'api/models/stats'
import { formatCurrency } from 'app/utils/format'
import React, { useEffect, useMemo, useState } from 'react'
import getLocales from 'app/utils/apexlocales.util'
import i18n from 'i18next'
import { Card, CardContent, useTheme, Box } from '@mui/material'
import localeData from 'dayjs/plugin/localeData'
import dayjs from 'dayjs'
import { ThemedChart } from 'app/components/charts/timeline.component'
import { CardSkeleton } from 'app/components/skeletons/card.skeleton'
dayjs.extend(localeData)

export const CaChartComponent = function ({
  rows,
  isLoading,
  height,
  params
}: {
  rows: StatsCA | undefined
  isLoading: boolean
  height: number
  params: URLSearchParams
}) {
  const [locales, setLocales] = useState<any>([])
  const theme = useTheme()
  const [formatedRows, setFormatedRows] = useState<any>([])

  const labels = useMemo(() => {
    let startDate = dayjs().startOf('month')
    const dateBegin = params.get('date_begin')
    if (dateBegin !== null) {
      startDate = dayjs(dateBegin).startOf('month')
    }
    const dates = []
    for (let i = 0; i < 12; i++) {
      const formattedDate = startDate.add(i, 'month').format('MM/DD/YYYY')
      dates.push(formattedDate)
    }
    return dates
  }, [params])

  useEffect(() => {
    getLocales().then((locales: any) => setLocales(locales))
    if (!rows) return
    const res = Object.entries(rows)
      .filter(([name]) => name !== 'Total' && name !== 'BP')
      .map(([name, values]) => ({
        name: name,
        data: values.map((value) => value),
        type: 'bar'
      }))
    setFormatedRows(res)
  }, [rows])

  const options = useMemo(() => {
    const data: ApexOptions = {
      chart: {
        type: 'bar',
        stacked: true,
        locales: [locales],
        defaultLocale: i18n.language,
        toolbar: {
          show: true
        },
        zoom: {
          enabled: false
        }
      },
      fill: {
        opacity: 1
      },
      tooltip: {
        custom: (data: any) => {
          const monthIndex = data.dataPointIndex
          const month = dayjs.months().at(monthIndex)
          const values = data.series.map((serie: any, index: any) => {
            if (data.w.globals.seriesX[index].length <= 0) {
              return null
            }
            const label = data.w.globals.seriesNames[index]
            const color = data.w.globals.colors[index]
            return {
              label,
              value: serie[monthIndex],
              color
            }
          })
          return (
            '<div class="timeline-tooltip-container">' +
            '<p class="timeline-tooltip-title" style="padding-left : 1rem">' +
            month?.toUpperCase() +
            '</p>' +
            '<div class="timeline-tooltip-content">' +
            values
              .map((data: any) => {
                if (!data) return ''
                return (
                  '<p>' +
                  '<span style="display:inline-block; width : 0.75rem; height : 0.75rem; margin-right : 7px; border-radius: 1rem; background-color : ' +
                  data.color +
                  '"></span>' +
                  '<span class="timeline-tooltip-x">' +
                  data.label +
                  ' : </span>' +
                  formatCurrency(data.value) +
                  '</p>'
                )
              })
              .join('') +
            '</div>' +
            '</div>'
          )
        }
      },
      xaxis: {
        type: 'datetime',
        categories: labels,
        tickAmount: 12,
        labels: {
          format: 'MMMM'
        }
      },
      yaxis: {
        labels: {
          formatter: (val: number) => {
            return formatCurrency(val)
          }
        }
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            legend: {
              position: 'bottom',
              offsetX: -10,
              offsetY: 0
            }
          }
        }
      ],
      legend: {
        position: 'top'
      },
      dataLabels: {
        enabled: false
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: '70%'
        }
      },
      theme: {
        palette: 'palette5',
        mode: theme.palette.mode
      }
    }
    return data
  }, [labels, theme.palette.mode, locales])

  return (
    <Card sx={{ overflow: 'visible' }}>
      <CardContent>
        {isLoading ? (
          <CardSkeleton height={height} />
        ) : (
          <Box className="line">
            <ThemedChart options={options} series={formatedRows} type="bar" height={height} />
          </Box>
        )}
      </CardContent>
    </Card>
  )
}
