export const customBlueFlexo = {
  50: '#eaf0f1',
  100: '#d5e1e4',
  200: '#c0d2d6',
  300: '#acc3c9',
  400: '#97b4bb',
  500: '#82a6ad',
  600: '#6d96a0',
  700: '#598892',
  800: '#306B77',
  900: '#225864'
}
export const customYellowFlexo = {
  50: '#f9efc5',
  100: '#f9e9a7',
  200: '#f9e698',
  300: '#f9e48e',
  400: '#f9e181',
  500: '#f9df77',
  600: '#f9da5e',
  700: '#f9d852',
  800: '#f9d543',
  900: '#FAD642'
}
